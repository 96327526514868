
import { Component, Vue } from 'vue-property-decorator';

// import components
import StatusList from '@/components/status/StatusList.vue';
// import StatusView from '@/components/status/StatusView.vue';
import StatusStatistics from '@/components/status/StatusStatistics.vue';

@Component({
    components: {
        StatusList,
        // StatusView,
        StatusStatistics,
    },
})
export default class StatusPage extends Vue {
    private name: string = "steves";

    private toggleStatistics() {
        this.$store.commit("toggleComponent", {
            page: "status",
            name: "StatusStatistics"
        })
    }
    private toggleList() {
        this.$store.commit("toggleComponent", {
            page: "status",
            name: "StatusList"
        })
    }

    private get statisticsVisible() {
        return this.$store.state.pages.status.components.includes("StatusStatistics");
    }
    private get listVisible() {
        return this.$store.state.pages.status.components.includes("StatusList");
    }
};
