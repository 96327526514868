// import store
import store from "../store/index";

// POST api/v1/status/create
const statusCreate = async () => {
    //TODO: implement
};
// GET api/v1/status/readall
const statusReadAll = async () => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/status/readall`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                categoryid: `${store.state.activeCategoryId}`
            },
        });
        // PARSE RESPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // MANIPULATE STORE
            store.commit("updateStatusCache", {
                statusList: jsonResponse.data
            });
            // return
            return jsonResponse.data;
        }
        else {
            // MANIPULATE STORE
            store.commit("updateStatusCache", {
                statusList: []
            });
            // return
            return [];
        }
    }
    catch (error) {
        // MANIPULATE STORE
        store.commit("updateStatusCache", {
            statusList: []
        });
        // return
        return [];
    }
};
// POST api/v1/status/setstatus
const statusSetStatus = async (statusId: any, status: any, negativeAction: any = null) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/status/setstatus/${statusId}`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.key}`,
            },
            body: JSON.stringify({
                status: status,
                negativeAction: negativeAction
            })
        });
        // PARSE RESPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // return
            return jsonResponse.data;
        }
        else {
            // return
            return null;
        }
    }
    catch (error) {
        // return
        return null;
    }
};
// GET api/v1/status/clear/:statusId
const statusClear = async (statusId: any) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/status/clear/${statusId}`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
            },
        });
        // PARSE RESPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // return
            return jsonResponse.data;
        }
        else {
            // return
            return false;
        }
    }
    catch (error) {
        // return
        return false;
    }
};
// GET api/v1/status/list/:statusAnswer
const statusList = async (statusAnswer: any) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/status/list/${statusAnswer}`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                categoryid: `${store.state.activeCategoryId}`
            },
        });
        // PARSE RESPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // MANIPULATE STORE
            store.commit("updateStatusCache", {
                statusList: jsonResponse.data
            });
            // return
            return jsonResponse.data;
        }
        else {
            // MANIPULATE STORE
            store.commit("updateStatusCache", {
                statusList: []
            });
            // return
            return [];
        }
    }
    catch (error) {
        // MANIPULATE STORE
        store.commit("updateStatusCache", {
            statusList: []
        });
        // return
        return [];
    }
};
// GET api/v1/status/statistics
const statusStatistics = async () => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/status/statistics`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                categoryid: `${store.state.activeCategoryId}`,
                "nekeyto-category": `${store.state.activeCategoryId}`
            },
        });
        // PARSE RESPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // MANIPULATE STORE
            store.commit("updateStatusCache", {
                statusStatistics: jsonResponse.data
            });
            // return
            return jsonResponse.data;
        }
        else {
            // MANIPULATE STORE
            store.commit("updateStatusCache", {
                statusStatistics: null
            });
            // return
            return null;
        }
    }
    catch (error) {
        // MANIPULATE STORE
        store.commit("updateStatusCache", {
            statusStatistics: null
        });
        // return
        return null;
    }
};
// GET api/v1/status/multiplayer
const statusMultiplayer = async () => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/status/multiplayer`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                categoryid: `${store.state.activeCategoryId}`
            },
        });
        // PARSE RESPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {

            // MANIPULATE STORE
            store.commit("setNekeytoCache", {nekeytoList: jsonResponse.data});
            console.log(jsonResponse.data);
            // return
            return jsonResponse.data;
        }
        else {
            // MANIPULATE STORE
            store.commit("setNekeytoCache", {nekeytoList: []});
            // return
            return [];
        }
    }
    catch (error) {
        // MANIPULATE STORE
        store.commit("setNekeytoCache", {nekeytoList: []});
        // return
        return [];
    }
};
// POST api/v1/status/multiplayer/resetcache
const statusMultiplayerResetCache = async () => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/status/multiplayer/resetcache`, {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`
            },
        });
        // PARSE RESPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // return
            return jsonResponse.data;
        }
        else {
            // return
            return false;
        }
    }
    catch (error) {
        // return
        return false;
    }
};
// GET api/v1/status/listforcustomer
const statusListForCustomer = async (customerId: string) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/status/listforcustomer/${customerId}`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
            },
        });
        // PARSE RESPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // MANIPULATE STORE
            store.commit("setNekeytoCache", {nekeytoList: jsonResponse.data});
            // return
            return jsonResponse.data;
        }
        else {
            store.commit("setNekeytoCache", {nekeytoList:  []});
            // return
            return [];
        }
    }
    catch (error) {
        store.commit("setNekeytoCache", {nekeytoList: []});
        // return
        return [];
    }
};
// GET api/v1/status/parkinglot/:statusId
const statusParkingLot = async (statusId: number) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/status/parkinglot/${statusId}`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
            },
        });
        // PARSE RESPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // return
            return jsonResponse.data;
        }
        else {
            // return
            return false;
        }
    }
    catch (error) {
        // return
        return false;
    }
};

// export
export {
    statusCreate,
    statusReadAll,
    statusSetStatus,
    statusClear,
    statusList,
    statusStatistics,
    statusMultiplayer,
    statusParkingLot,
    statusListForCustomer,
    statusMultiplayerResetCache
};