const CONSTANTS = {
    AD_GROUP: {},
    AD_GROUP_TYPE: {},
    ALERT: {
        // NORMAL
        ALERT_TYPE: {
            NONE: 0,
            BAD_COLLISION: 10
        },
        // LOOKUP
        ALERT_TYPE_R : {
            0: "NONE",
            10: "BAD_COLLISION"
        },
        // NORMAL
        ALERT_SEVERITY: {
            NONE: 0,
            WARNING: 80,
            CRITICAL: 99
        },
        // LOOKUP
        ALERT_SEVERITY_R: {
            0: "NONE",
            80: "WARNING",
            99: "CRITICAL",
        },
    },
    CAMPAIGN: {},
    CAMPAIGN_TYPE: {},
    CATEGORY: {},
    CUSTOMER: {},
    CHANGE_EVENT: {
        CHANGE_EVENT_EVENT_TYPE: {
            // BLANK
            BLANK: 0,
            UN_TRACKED: 1,

            // AD BASIC
            CREATE_AD: 100,
            EDIT_AD: 101,
            REMOVE_AD: 102,
            // AD STATUS
            PAUSE_AD: 103,
            ENABLE_AD: 104,

            // AD GROUP BASIC
            CREATE_AD_GROUP: 200,
            EDIT_AD_GROUP: 201,
            REMOVE_AD_GROUP: 202,
            // AD GROUP STATUS
            PAUSE_AD_GROUP: 203,
            ENABLE_AD_GROUP: 204,
            // AD GROUP KEYWORDS
            ADD_KEYWORD_AD_GROUP: 205,
            EDIT_KEYWORD_AD_GROUP: 206,
            REMOVE_KEYWORD_AD_GROUP: 207,
            // AD GROUP NEGATIVE KEYWORD
            ADD_NEGATIVE_KEYWORD_AD_GROUP: 208,
            EDIT_NEGATIVE_KEYWORD_AD_GROUP: 209,
            REMOVE_NEGATIVE_KEYWORD_AD_GROUP: 210,
            // AD GROUP BUDGET
            CHANGE_BID_ADJUSTMENT_AD_GROUP: 211,

            // CAMPAIGN BASIC
            CREATE_CAMPAIGN: 300,
            EDIT_CAMPAIGN: 301,
            REMOVE_CAMPAIGN: 302,
            // CAMPAIGN STATUS
            PAUSE_CAMPAIGN: 303,
            ENABLE_CAMPAIGN: 304,
            // CAMPAIGN NEGATIVE KEYWORD
            ADD_NEGATIVE_KEYWORD_CAMPAIGN: 305,
            EDIT_NEGATIVE_KEYWORD_CAMPAIGN: 306,
            REMOVE_NEGATIVE_KEYWORD_CAMPAIGN: 307,
            // CAMPAIGN BUDGET
            CHANGE_BUDGET_CAMPAIGN: 308,
            CHANGE_BID_ADJUSTMENT_CAMPAIGN: 309,
            // CAMAPIGN LOCATION
            CHANGE_LOCATION_CAMPAIGN: 310,
        },
        CHANGE_EVENT_EVENT_TYPE_R: {
            // BLANK
            0: "BLANK",
            1: "UN_TRACKED",

            // AD BASIC
            100: "CREATE_AD",
            101: "EDIT_AD",
            102: "REMOVE_AD",
            // AD STATUS
            103: "PAUSE_AD",
            104: "ENABLE_AD",

            // AD GROUP BASIC
            200: "CREATE_AD_GROUP",
            201: "EDIT_AD_GROUP",
            202: "REMOVE_AD_GROUP",
            // AD GROUP STATUS
            203: "PAUSE_AD_GROUP",
            204: "ENABLE_AD_GROUP",
            // AD GROUP KEYWORDS
            205: "ADD_KEYWORD_AD_GROUP",
            206: "EDIT_KEYWORD_AD_GROUP",
            207: "REMOVE_KEYWORD_AD_GROUP",
            // AD GROUP NEGATIVE KEYWORD
            208: "ADD_NEGATIVE_KEYWORD_AD_GROUP",
            209: "EDIT_NEGATIVE_KEYWORD_AD_GROUP",
            210: "REMOVE_NEGATIVE_KEYWORD_AD_GROUP",
            // AD GROUP BUDGET
            211: "CHANGE_BID_ADJUSTMENT_AD_GROUP",

            // CAMPAIGN BASIC
            300: "CREATE_CAMPAIGN",
            301: "EDIT_CAMPAIGN",
            302: "REMOVE_CAMPAIGN",
            // CAMPAIGN STATUS
            303: "PAUSE_CAMPAIGN",
            304: "ENABLE_CAMPAIGN",
            // CAMPAIGN NEGATIVE KEYWORD
            305: "ADD_NEGATIVE_KEYWORD_CAMPAIGN",
            306: "EDIT_NEGATIVE_KEYWORD_CAMPAIGN",
            307: "REMOVE_NEGATIVE_KEYWORD_CAMPAIGN",
            // CAMPAIGN BUDGET
            308: "CHANGE_BUDGET_CAMPAIGN",
            309: "CHANGE_BID_ADJUSTMENT_CAMPAIGN",
            // CAMAPIGN LOCATION
            310: "CHANGE_LOCATION_CAMPAIGN",
        }
    },
    NEGATIVE_ACTION: {
        // NORMAL
        NEGATIVE_ACTION_MATCH_TYPE: {
            NONE: 0,
            UNKNOWN: 1,
            EXACT: 2,
            PHRASE: 3,
            BROAD: 4
        },
        // REVERSED
        NEGATIVE_ACTION_MATCH_TYPE_R: {
            0: "NONE",
            1: "UNKNOWN",
            2: "EXACT",
            3: "PHRASE",
            4: "BROAD"
        },
        // NORMAL
        NEGATIVE_ACTION_ACTION_TYPE: {
            NONE: 0,
            ALL: 10,
            CATEGORY: 20,
            CUSTOMER: 30,
            CAMPAIGN_TYPE: 40,
            CAMPAIGN: 50,
            AD_GROUP_TYPE: 60,
            AD_GROUP: 70
        },
        // REVERSED
        NEGATIVE_ACTION_ACTION_TYPE_R: {
            0: "NONE",
            10: "ALL",
            20: "CATEGORY",
            30: "CUSTOMER",
            40: "CAMPAIGN_TYPE",
            50: "CAMPAIGN",
            60: "AD_GROUP_TYPE",
            70: "AD_GROUP",
        },
        // NORMAL
        NEGATIVE_ACTION_ACTION_STATUS: {
            NONE: 0,
            CREATED: 10,
            VALIDATING: 20,
            VALIDATED: 30,
            HANDLING: 40,
            HANDLED: 50,
            FAILED: 60
        },
        // REVERSED
        NEGATIVE_ACTION_ACTION_STATUS_R: {
            0: "NONE",
            10: "CREATED",
            20: "VALIDATING",
            30: "VALIDATED",
            40: "HANDLING",
            50: "HANDLED",
            60: "FAILED"
        }
    },
    SEARCH_TERM: {
        // NORMAL
        SEARCH_TERM_PROCESSED_STATE: {
            NONE: 0,
            FAILED: 10,
            BUSY: 20,
            COMPLETE: 90
        },
        // REVERSED
        SEARCH_TERM_PROCESSED_STATE_R: {
            0: "NONE",
            10: "FAILED",
            20: "BUSY",
            90: "COMPLETE"
        },
    },
    STATUS: {
        // NORMAL
        STATUS_ANSWER: {
            NONE: 0,
            GOOD: 10,
            BAD: 20,
            PARKING_LOT: 50
        },
        // REVERSED
        STATUS_ANSWER_R: {
            0: "NONE",
            10: "GOOD",
            20: "BAD",
            50: "PARKING_LOT"
        },
        // NORMAL
        STATUS_LEVEL: {
            NONE: 0,
            ALL: 10,
            CATEGORY: 20,
            CUSTOMER: 30,
            CAMPAIGN_TYPE: 40,
            CAMPAIGN: 50,
            AD_GROUP_TYPE: 60,
            AD_GROUP: 70
        },
        // REVERSED
        STATUS_LEVEL_R: {
            0: "NONE",
            10: "ALL",
            20: "CATEGORY",
            30: "CUSTOMER",
            40: "CAMPAIGN_TYPE",
            50: "CAMPAIGN",
            60: "AD_GROUP_TYPE",
            70: "AD_GROUP"
        },
    },
    UNIQUE_SEARCH_TERM: {},
    USER: {
        // NORMAL
        USER_ACCESS: {
            DEVELOPER: 80,
            ADMIN: 60,
            MANAGER: 40,
            BASIC: 20,
            NONE: 0
        },
        // REVERSED
        USER_ACCESS_R: {
            80: "DEVELOPER",
            60: "ADMIN",
            40: "MANAGER",
            20: "BASIC",
            0: "NONE"
        }
    }
    
};

export { 
    CONSTANTS
};